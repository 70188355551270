<template>
  <div class="entity-header-container">
    <div class="submit-review" v-if="reviewMode">
      <button class="submit-review-btn" @click="submitForReview">
        <span v-if="isSubmitForStaging" class="submit-review-text">
          Submit for Staging</span
        >
        <span v-else-if="isSubmitForReview" class="submit-review-text">
          Submit for Review</span
        >
        <span v-else-if="isSubmitForDelivery" class="submit-review-text">
          Submit for Delivery</span
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="20.543" height="20.551" viewBox="0 0 20.543 20.551" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#fff"><path data-name="Path 16764" d="M4.049 20.55a.884.884 0 01-.615-1.085.872.872 0 01.8-.647c.087-.005.174 0 .26 0H16.24a2.531 2.531 0 002.486-1.968 2.417 2.417 0 00.066-.555V4.289a2.526 2.526 0 00-2.526-2.527q-5.943-.006-11.886 0a.889.889 0 01-.961-.7.868.868 0 01.768-1.041c.073-.005.147 0 .22 0 3.928 0 7.857.019 11.786-.008a4.337 4.337 0 014.286 3.525 4.291 4.291 0 01.064.777v11.966a4.281 4.281 0 01-3.606 4.251c-.046.008-.091.021-.136.032z"/><path data-name="Path 16765" d="M0 10.046a.976.976 0 011.02-.648q5.531.006 11.063 0h.257c-.015-.113-.085-.15-.134-.2q-.841-.845-1.685-1.687a.868.868 0 01-.237-.9.83.83 0 01.659-.606.883.883 0 01.852.287q1.075 1.079 2.154 2.154.6.595 1.193 1.187a.885.885 0 01.016 1.29q-1.686 1.687-3.377 3.369a.876.876 0 01-1.471-.255.864.864 0 01.224-.991l1.671-1.673c.049-.05.119-.087.132-.173a.427.427 0 00-.252-.026H1.022a.982.982 0 01-1.021-.648z"/></g></svg>
      </button>
    </div>
    <div class="entity-info">
      <p class="info-title">
        {{ entityDetails[0]?.entityName ?? 'N/A' }}
        &nbsp;
        <span class="button-icon" v-if="entityDetails[0]?.isOnHold">
          <SvgIcon :icon="lockIcon" :size="'xxlarge'" class="icon" />
        </span>
      </p>
      <p class="date-updated">
        Last Updated:
        {{
          entityDetails[0]?.lastUpdated
            ? getLastUpdatedDate(entityDetails[0].lastUpdated)
            : 'N/A'
        }}
      </p>
    </div>
    <div class="review-pane" v-if="!reviewMode">
      <div class="status-info">
        Current Status
        <div class="status-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16.618" height="20.321" viewBox="0 0 16.618 20.321" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#00218a"><path data-name="Path 16766" d="M8.327 20.309H3.813a3.712 3.712 0 01-3.776-3.223 3.305 3.305 0 01-.025-.492c0-1.833.025-3.653-.012-5.486a3.729 3.729 0 012.989-3.665.232.232 0 00.221-.271v-2a5.094 5.094 0 0110.123-.873 23.431 23.431 0 01.074 2.632v.258c-.012.16.037.234.221.271a3.709 3.709 0 012.977 3.21c.012.148.012.283.012.431v5.5a3.694 3.694 0 01-3.727 3.727c-1.512-.007-3.04-.019-4.563-.019zM8.315 9.263h-4.49a1.808 1.808 0 00-1.919 1.919v5.314a1.817 1.817 0 001.919 1.931h9a1.817 1.817 0 001.925-1.931v-5.314a1.824 1.824 0 00-1.943-1.931c-1.503.012-2.991.012-4.492.012zm0-1.882h2.927c.2 0 .283-.049.271-.258a58.6 58.6 0 010-2.054 3.186 3.186 0 00-6.372-.049c-.012.7 0 1.415 0 2.116 0 .184.062.246.246.246z"/><path data-name="Path 16767" d="M8.327 14.318a1.4 1.4 0 111.4-1.39 1.4 1.4 0 01-1.4 1.39z"/></g></svg>
          <span>{{ entityDetails[0]?.currentStatus ?? 'N/A' }}</span>
        </div>
      </div>
      <div v-if="!isOnHoldValidationPass">
        <button
          v-if="entityDetails[0]?.isOnHold"
          class="review-btn"
          @click="onHoldStatusChange('No')"
        >
          <span>Remove On Hold</span>
        </button>
        <button v-else class="review-btn" @click="onHoldStatusChange('Yes')">
          <span>Move To On Hold</span>
        </button>
      </div>

      <button
        class="review-btn"
        data-tooltip="Entity is On-Hold, you can't submit it."
        :disabled="disabledOnHold"
        :class="disabledOnHold ? 'review-btn-disabled' : ''"
        @click="submitForReview"
        v-if="!isSubmitDisabled"
      >
        <span v-if="isSubmitForStaging" class="review-text">
          Submit for Staging</span
        >
        <span v-else-if="isSubmitForReview" class="review-text">
          Submit for Review</span
        >
        <span v-else-if="isSubmitForDelivery" class="review-text">
          Submit for Delivery</span
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="20.543" height="20.551" viewBox="0 0 20.543 20.551" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#fff"><path data-name="Path 16764" d="M4.049 20.55a.884.884 0 01-.615-1.085.872.872 0 01.8-.647c.087-.005.174 0 .26 0H16.24a2.531 2.531 0 002.486-1.968 2.417 2.417 0 00.066-.555V4.289a2.526 2.526 0 00-2.526-2.527q-5.943-.006-11.886 0a.889.889 0 01-.961-.7.868.868 0 01.768-1.041c.073-.005.147 0 .22 0 3.928 0 7.857.019 11.786-.008a4.337 4.337 0 014.286 3.525 4.291 4.291 0 01.064.777v11.966a4.281 4.281 0 01-3.606 4.251c-.046.008-.091.021-.136.032z"/><path data-name="Path 16765" d="M0 10.046a.976.976 0 011.02-.648q5.531.006 11.063 0h.257c-.015-.113-.085-.15-.134-.2q-.841-.845-1.685-1.687a.868.868 0 01-.237-.9.83.83 0 01.659-.606.883.883 0 01.852.287q1.075 1.079 2.154 2.154.6.595 1.193 1.187a.885.885 0 01.016 1.29q-1.686 1.687-3.377 3.369a.876.876 0 01-1.471-.255.864.864 0 01.224-.991l1.671-1.673c.049-.05.119-.087.132-.173a.427.427 0 00-.252-.026H1.022a.982.982 0 01-1.021-.648z"/></g></svg>
      </button>
    </div>

    <div class="review-pane" v-if="reviewMode">
      <button class="review-action-btn btn-edit" @click="onEdit">Edit</button>
      <button class="review-action-btn btn-confirm" @click="onConfirm">
        Confirm
      </button>
    </div>

    <template>
      <ConfirmationDialog
        class="on-hold-dialog"
        :visible="confirmationDialogData.visible"
        :title="confirmationDialogData.title"
        :bodyText="confirmationDialogData.text"
        :cancelButtonText="confirmationDialogData.cancelButtonText"
        :confirmButtonText="confirmationDialogData.confirmButtonText"
        :closeDialog="closeOnHoldDialog"
        :triggerFunction="confirmationDialogData.triggerFunction"
      >
        <SearchableSelect
          v-if="isOnHoldDropdown"
          :value="onHoldReason"
          label="On Hold Reason"
          :placeholder="'Select on hold reason'"
          :data="onHoldReasons"
          value_field="value"
          text_field="text"
          @onChangeName="handleOnHoldReason"
          class="form-input-field"
          :isMandatory="true"
          :mandatorySubmit="onHoldSubmit"
          :submitted="onHoldSubmit"
        />
      </ConfirmationDialog>
    </template>
  </div>
</template>

<script>
import { checkPathPermission } from '@/util/permissions.utils'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { entityStatus } from '@/constant/data.js'
import { lockIcon } from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'
import { userRoles } from '@/util/permissions.utils'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'

export default {
  name: 'EntityFormHeader',

  components: {
    SvgIcon,
    ConfirmationDialog,
    SearchableSelect
  },

  props: {
    reviewMode: Boolean
  },

  data() {
    return {
      lockIcon,
      onHoldReason: null,
      isOnHoldDropdown: false,
      onHoldSubmit: false,
      loading: false,
      isClose: true,
      confirmationDialogData: {
        visible: false,
        title: 'Confirmation',
        text: 'Are you sure you want to continue?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        triggerFunction: () => {}
      }
    }
  },

  computed: {
    ...mapState('assignedEntities', ['entityDetails', 'isSubmittedEntity']),
    ...mapState('user', ['userDetails']),
    ...mapGetters('dmp', ['getEntityDetails']),
    ...mapState('workflow', ['onHoldReasons']),
    isSubmitForReview() {
      return checkPathPermission('submit-for-review', this.userDetails.roleName)
    },
    isSubmitForDelivery() {
      return checkPathPermission(
        'submit-for-delivery',
        this.userDetails.roleName
      )
    },
    isSubmitForStaging() {
      return checkPathPermission(
        'submit-for-staging',
        this.userDetails.roleName
      )
    },

    isOnHoldValidationPass() {
      const assignedTo = this.entityDetails[0]?.assignedTo
      const currentStatus = this.entityDetails[0]?.currentStatus
      let hasInvalidStatus = false
      // Check if the user's role is PM_MANAGER or PG_M and set invalid status accordingly
      if (
        this.userDetails.roleName === userRoles.PM_MANAGER ||
        this.userDetails.roleName === userRoles.PG_M
      ) {
        hasInvalidStatus = [entityStatus?.SUBMIT]
          .map((status) => status)
          .includes(currentStatus) // Only 'SUBMIT' is invalid for these roles
      } else {
        hasInvalidStatus = [entityStatus?.SUBMIT, entityStatus?.STAGING]
          .map((status) => status)
          .includes(currentStatus) // 'SUBMIT' or 'STAGING' are invalid for other roles
      }

      const hasUnassignedItems = !assignedTo
      return hasInvalidStatus || hasUnassignedItems
    },

    isSubmitDisabled() {
      // If entity status is submit then disable edit & confirm button
      return this.isSubmittedEntity
    },

    disabledOnHold() {
      const roles = [
        userRoles.DIRECT_SUBMITTER,
        userRoles.ANALYST_MAKER,
        userRoles.ANALYST_CHECKER,
        userRoles.PG_M,
        userRoles.PM_MANAGER
      ]
      return (
        this.entityDetails[0]?.isOnHold &&
        roles.includes(this.userDetails.roleName)
      )
    }
  },
  async mounted() {
    await this.getHeaderDetails()
  },
  methods: {
    ...mapActions('assignedEntities', ['getEntityDetailsById']),
    ...mapActions('workflow', ['getOnHoldReasons', 'updateOnHoldStatus']),

    getLastUpdatedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async getHeaderDetails() {
      const { sgaId } = this.getEntityDetails
      await this.getEntityDetailsById({ sgaId })
    },
    closeOnHoldDialog() {
      if (this.isClose) {
        this.confirmationDialogData.visible = false
        this.onHoldReason = null
      } else {
        this.confirmationDialogData.visible = true
        this.isClose = true
      }
    },
    submitForReview() {
      // Add  review submission logic here
      this.$emit('onSubmit', true)
    },

    onEdit() {
      this.$emit('onEdit', false)
    },

    onConfirm() {
      this.$emit('onConfirm', true)
    },
    handleOnHoldReason(onHoldReason) {
      this.onHoldReason = onHoldReason
    },
    async onHoldStatusChange(isOnHoldSelect) {
      // Destructure relevant details from the entity and state
      const { sgaId } = this.getEntityDetails
      const { entityName, requestId } = this.entityDetails[0]

      if (isOnHoldSelect === 'Yes') {
        this.isOnHoldDropdown = true
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'On Hold Confirmation'
        this.confirmationDialogData.text = `Are you sure you want to change On-Hold status from <b>No to Yes</b>? <br/><br/>
           SGA ID: ${sgaId}  <br/> Legal Entity Name: ${entityName}</br> Request Id: ${requestId}`
        this.confirmationDialogData.triggerFunction = async () => {
          this.onHoldSubmit = true
          this.isClose = false
          if (this.onHoldReason !== null) {
            this.isClose = true
            this.$emit('onLoad', true)

            // Update On-Hold status for the entity
            await this.updateOnHoldStatus({
              sgaId: [sgaId],
              isOnHold: isOnHoldSelect,
              onHoldReasonId: this.onHoldReason.value
            })
            await this.getHeaderDetails()
            this.confirmationDialogData.visible = false
            this.onHoldReason = null // Reset On-Hold reason
            this.$emit('onLoad', false)
          }
        }
      } else {
        // Show confirmation dialog for changing the status to "No"
        this.isOnHoldDropdown = false
        this.confirmationDialogData.visible = true
        this.confirmationDialogData.title = 'On Hold Confirmation'
        this.confirmationDialogData.text = `Are you sure you want to change On-Hold status from <b>Yes to No</b>?<br/><br/>
           SGA ID: ${sgaId}  <br/> Legal Entity Name: ${entityName}</br> Request Id: ${requestId}`
        this.confirmationDialogData.triggerFunction = async () => {
          this.$emit('onLoad', true)

          // Update On-Hold status to No for the entity
          await this.updateOnHoldStatus({
            sgaId: [sgaId],
            isOnHold: isOnHoldSelect,
            onHoldReasonId: null
          })
          await this.getHeaderDetails()
          this.confirmationDialogData.visible = false
          this.onHoldReason = null // Reset On-Hold reason
          this.$emit('onLoad', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 14px;
  position: relative;

  .submit-review {
    flex: 0 0 281px;

    .submit-review-btn {
      background-color: transparent;
      color: var(--inava-primary-dark);
      border: 1px solid var(--inava-primary-dark);
      padding: 9px 15px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .review-text {
        padding-right: 4px;
      }

      svg {
        g {
          fill: #00218a;
        }
      }
    }
  }

  ::v-deep .entity-info {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .info-title {
      margin: 0;
      font-size: 30px;
      letter-spacing: 0px;
      color: var(--inava-primary-dark);
      font-weight: 600;
      display: inline-flex;
      margin-right: 15px;
      .button-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // SVG Icon color
        svg {
          fill: #ff0000;
          stroke: #ff0000;
        }
      }
    }

    .date-updated {
      display: inline-block;
      color: #1e1e1e;
      font-size: 18px;
      letter-spacing: 0.18px;
      padding-bottom: 5px;
    }
  }

  .review-pane {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    gap: 22px;

    .status-info {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #888888;

      .status-box {
        background-color: #c4c8f8;
        padding: 9px 15px;
        border-radius: 5px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        cursor: default;

        span {
          padding-left: 6px;
          font-size: 18px;
          color: var(--inava-primary-dark);
        }
      }
    }

    .review-btn {
      padding: 9px 15px;
      color: #ffffff;
      border-radius: 4px;
      font-size: 18px;
    }

    .review-btn-disabled {
      cursor: not-allowed;
      background-color: #d3d3d3;
      color: #a9a9a9;
      position: relative;
    }

    .review-btn-disabled::after {
      content: attr(data-tooltip);
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333333;
      color: #ffffff;
      padding: 5px 8px;
      font-size: 12px;
      white-space: nowrap;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }

    .review-btn-disabled:hover::after {
      opacity: 1;
    }

    .review-action-btn {
      display: inline-block;
      padding: 9px 15px;
      text-align: center;
      color: #ffffff;
      border-radius: 4px;
      font-size: 18px;
      width: 98px;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .btn-edit {
      border: 1px solid #888888;
      background-color: #888888;
    }

    .btn-confirm {
      border: 1px solid #00218a;
      background-color: #00218a;
    }
  }

  .review-btn {
    background-color: var(--inava-primary-dark);
    color: white;
    border: none;
    padding: 9px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .review-text {
      padding-right: 4px;
    }
  }
  ::v-deep .on-hold-dialog {
    .k-dialog-wrapper .k-dialog {
      width: 30%;
    }

    .k-window-content {
      overflow: unset;

      .popup-msg {
        text-align: left;
        margin-left: 0;
      }
    }
  }
}
</style>
